<template>
  <div class="zy-image-text">
    <div class="zy-image-text-item" :style="{ height: `${height+4}px` }" v-for="(item, index) in list" :key="index" @click="handleClick(item)">
      <el-image v-if="item.contentType != 1" class="zy-image-text-item-image" :style="{ height: `${height}px` }" :src="item.imgUrl" alt="暂无数据"></el-image>
      <el-image v-else class="zy-image-text-item-image" :style="{ height: `${height}px` }" :src="item.imgUrl" :preview-src-list="[item.imgUrl]" alt="暂无数据"></el-image>
      <div v-if="item.contentTitle" class="zy-image-text-item-title">{{ item.contentTitle }}</div>
    </div>
    <zy-dialog :visible="visible" :title="dialogTitle" @close="closeDialog">
      <div class="content">
        <template v-if="noticeDetail.contentType == 0">
          <p class="content-title">{{ noticeDetail.contentTitle }}</p>
          <div class="content-detail" v-html="noticeDetail.content"></div>
        </template>
        <video v-if="noticeDetail.contentType == 2" id="myVideo" width="100%" controls>
          <source :src="noticeDetail.videoUrl" type="video/mp4" />
        </video>
      </div>
    </zy-dialog>
  </div>
</template>

<script>
import components from '@/view/jxyh/components/index.js'
import { getContentDetail } from '@/jxyh/api/quality'
export default {
  ...components(['ZyDialog']),
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    height: {
      type: Number,
      default: 220
    },
    dialogTitle: {
      type: String,
      default: '质量亮点'
    }
  },
  data() {
    return {
      visible: false,
      noticeDetail: {},
      srcList: [],
    }
  },
  methods: {
    handleClick(item) {
      if(!item.id) return
      // 图文
      if (item.contentType != 1)
        getContentDetail({ id: item.id }).then((res) => {
          this.noticeDetail = res.data
          this.visible = true
        })
    },
    closeDialog() {
      this.visible = false
    },
  }
}
</script>

<style lang="less" scoped>
.zy-image-text {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  &-item {
    position: relative;
    border: 2px solid #1e5087;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 0px 2px #1e5087;
      transform: scale(1.01);
      transition: all 0.5s;
    }
    &-image {
      width: 366px;
    }
    &-title {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 366px;
      height: 48px;
      padding: 14px;
      box-sizing: border-box;
      font-size: 14px;
      color: #ffffff;
      margin-top: 10px;
      background: rgba(28, 94, 203, 0.9);
      opacity: 0.9;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
.content {
  &-title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    padding-bottom: 30px;
    word-wrap: break-word;
    border-bottom: 1px solid #ffffff20;
  }
  &-detail {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    word-wrap: break-word;
  }
}
</style>